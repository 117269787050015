module.exports = {
  intro: {
    title: "הבית החם ליזמים",
    desc: "תכנון ופיתוח מוצרים מהשלב שבו יש לכם רעיון\u00A0עד\u00A0למוצר\u00A0מנצח!",
    addDesc:
      "אנו מתמחים במגוון רחב של שרותים כגון: עיצוב מוצר, תכן מכאני, תכן אלקטרומכני, פלסטיקה, זיווד, מידול, פיתוח לייצור וייצור\u00A0סדרתי",
    cta: "התחל פרוייקט",
  },
  whatWeDo: [
    {
      name: "plan",
      title: "תכנון ועיצוב",
      desc: "תכנון ועיצוב - אפיון מוצר ובדיקת היתכנות, תוך כדי התחשבות בסקר שוק, ניתוח ממצאים והדמית מוצר איכותית עם מתן דגש בממשק וחווית\u00A0משתמש",
    },
    {
      name: "prot",
      title: "בניית אבטיפוס",
      desc: "העברה מהדמיה לייצור, כאשר כל תהליך בניית האבטיפוס מתבצע במעבדות והסדנאות שלנו המותאמות למידול מוצרים בסטנדרט הגבוהה ביותר ומתן פתרון למכלול רחב של תחומים\u00A0הנדסיים",
    },
    {
      name: "prod",
      title: "ייצור המוני",
      desc: 'ניהול קו ייצור, קישור למפעלי ייצור ובתי מלאכה, ביקורת איכות ומשלוח המוצר ליעדים בארץ\u00A0ובח"ל',
    },
  ],
  ourSpecial: {
    cwtitle: "ליווי וייעוץ",
    cwdesc:
      "ג'נקו חורטת על דגלה לבצע ייעוץ לאורך כל תקופת הפיתוח, בניית תכנית עסקית, בדיקת היתכנות והתאמה אישית של התהליך בין היזם\u00A0לחברה",
    sptitle: "רישום ועריכת\u00A0פטנט",
    spdesc:
      "חברתנו ערוכה להליך הקריטי של רישום הפטנט, רישומו ועריכתו. הפטנט הוא רעיון שיש בו חדשנות וכדי לקבל זכות שמגינה על בעל הפטנט כדי למנוע שימוש על ידי מתחרים, לנו יש את הניסיון בביצוע\u00A0הליך\u00A0זה",
    qctitle: "בקרת איכות",
    qcdesc:
      "בחברתנו הלקוח מקבל מענה מלא, מיידי ויעיל. איננו מוציאים את העבודה למיקור חוץ או לחברות חיצוניות, אלא כל הידע והניסיון שנצבר בחברה הועבר על ידי תהליך ארוך וקפדני ובסופו של דבר הלקוח יוצא מרוצה ומתבצע תהליך קפדני לצורך בקרת איכות לאורך כל שלבי פיתוח ועיצוב\u00A0המוצר",
    ittitle: "איתור משקיעים",
    itdesc:
      "תחום ההשקעות הוא נר לרגלנו, חברתנו מתמחה כבר מספר שנים בתהליך המורכב שליבתו היא הצלחת הפרויקט, ואלה הם חלק ממרכיביו: גיוס המונים, ONE PAGER, מצגת למשקיעים, עזרה בגיוס משקיעים, הכנה לפגישה עם\u00A0משקיעים",
  },
  clients: {
    title: "לקוחות ושיתופי פעולה",
  },
  dir: "rtl",
};

module.exports = {
  seo: {
    title: "המלצות - Jenko",
    description: "",
  },
  articles: {
    title: "כתבות",
    articlesList: [
      {
        title: "מחזון למציאות",
        desc: "מצודה - מגזין  האבטחה של ישראל",
        date: "מאי 2021",
        label: "metsuda",
        url: "jenko-metsuda-article.pdf",
      },
      {
        title: "“מברמן למנכ”ל חברת הנדסה”",
        desc: null,
        date: "האונ’ הפתוחה - אפריל 2019",
        label: "lecture",
        url: "https://youtu.be/5R4MyvCIn2I",
      },
    ],
  },
  recommends: {
    title: "לקוחות ממליצים",
    recommendsList: [
      {
        label: "amitgreen",
        name: "עמית גרין",
        role: "יזם פרטי",
        text: "”במשך תקופה ארוכה ישבו לי מספר רעיונות בראש וכשהגעתי לג'נקו הרגשתי שמצאתי בית חם לרעיונות שלי. מהפגישה הראשונה ביצענו סיעור מוחות, ניהול סיכונים, הכוונה וצפי לכל התהליך שנקרא מרעיון למוצר. מצאתי חברה שלקחה את הרעיון הגולמי שלי ופיתחה אותו למוצר שיכול להצליח, זה התבטא באכפתיות, חשיבה מחוץ לקופסא, חריצות ומקצועיות ללא פשרות אני ממליץ בחום לכל יזם, חבר ועסק להתחיל את הפרויקט בפגישה בג'נקו, אני סמוך ובטוח שכמוני וכמו רבים אחרים כבר מהפגישה הראשונה תבינו\u00A0למה\u00A0התכוונתי\u00A0בבית.“",
      },
      {
        label: "talhadas",
        name: "טל הדס",
        role: "סמנכ”ל שיווק, חברת CATHETRIX",
        textMain:
          "לאחר מספר נסיונות של פיתוח מוצר רפואי עם מספר חברות שונות, הגענו אל חברת ג’נקו הנדסה. כבר במפגש הראשון הייתה הבנה של חזון המוצר ואנחנו הבנו שמצאנו את החברה שחיפשנו. שירותיות, ראש גדול, מהירות ויחס אישי, הורגשו לאורך כל משך העבודה מולם. לאחר כחודש וחצי הוצגו לפנינו אב טיפוס סופי ומשם הדרך לפיילוט מוצלח כבר הייתה קצרה. אנחנו ממליצים בחום רב על חברת ג’נקו הנדסה ומחכים להמשך עבודה משותף בעתיד הקרוב על פרוייקטים\u00A0חדשים\u00A0וחדשניים.",
        text: "”לאחר מספר נסיונות של פיתוח מוצר רפואי עם מספר חברות שונות, הגענו אל חברת ג’נקו הנדסה. כבר במפגש הראשון הייתה הבנה של חזון המוצר ואנחנו הבנו שמצאנו את החברה שחיפשנו. שירותיות, ראש גדול, מהירות ויחס אישי, הורגשו לאורך כל משך העבודה מולם. לאחר כחודש וחצי הוצגו לפנינו אב טיפוס סופי ומשם הדרך לפיילוט מוצלח כבר הייתה קצרה. אנחנו ממליצים בחום רב על חברת ג’נקו הנדסה ומחכים להמשך עבודה משותף בעתיד הקרוב על פרוייקטים\u00A0חדשים\u00A0וחדשניים.“",
      },
      {
        label: "denedict",
        name: "ד”ר ישעיהו בנדיקט",
        role: "מנכ”ל ובעלים של בנדיקט אורטופדיה\u00A0בע”מ",
        text: "”לאחר שרשמתי פטנט עולמי לתותב בתוך לשדי שמשקם עצמות, נפגשתי עם מספר חברות של מכשור רפואי על מנת שיבנו לי אב טיפוס אך ללא הצלחה. לאחר כשנה וחצי הגעי לפגישה בג’נקו, לא עברו שבועיים והם מצאו דרך להגשים את החלום שלי ובנו לי אב טיפוס עם מנגנון מכאני זעיר וגאוני! באותו הרגע הבנתי מה המשמעות של חשיבה מחוץ לקופסא. בזכות ג’נקו אזכה למכור ולעזור למטופלים\u00A0בכל\u00A0העולם.“",
      },
    ],
  },
  dir: "rtl",
};
